import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from '../layouts/Page';

export default function Template() {
  const [isNotTracking, setIsNotTracking] = useState(
    typeof window !== `undefined` && localStorage?.ty_dnt === 'true'
  );

  return (
    <PageLayout>
      <Helmet>
        <title>Gestion des cookies</title>
      </Helmet>
      <div className="blog-post-container">
        <div className="blog-post">
          <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
              <h1 className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Gestion des cookies
              </h1>
              <div className="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">
                <p>
                  Cette page vous permet de mieux comprendre quels avantages
                  vous apporte l&apos;utilisation de « Cookies » sur notre site
                  Internet. Lors de votre visite sur Thank-you.io, des
                  informations relatives à votre navigation sont susceptibles
                  d'être enregistrées dans des fichiers appelés "Cookies"
                  installés sur votre navigateur.
                </p>

                <h2>Qu'est-ce qu'un cookie ?</h2>
                <p>
                  Un cookie est un petit fichier contenant diverses
                  informations. Il est déposé sur ordinateur, tablette,
                  smartphone via votre navigateur, par le site web que vous
                  consultez.
                </p>
                <h2>Types de cookies utilisés</h2>
                <h3>Les cookies de fonctionnement</h3>
                <p>
                  Ces cookies sont nécessaires au bon fonctionnement de notre
                  site et vous permettent d'utiliser les fonctionnalités de
                  base. Sans ces cookies, vous ne pourrez pas utiliser notre
                  site de manière fonctionnelle. D'autres cookies nous servent à
                  connaître l'utilisation et la performance de notre site et
                  d'en améliorer le fonctionnement et votre expérience sur notre
                  site.
                </p>
                <h3>Les cookies de confort</h3>
                <p>
                  Ces cookies amènent des fonctionnalités supplémentaires qui
                  sont susceptibles d'intéresser l'internaute, sans pour autant
                  être indispensable au fonctionnement de base du site.
                </p>
                <h3>Les cookies analytiques</h3>
                <p>
                  Ces cookies nous permettent de connaître l'utilisation et les
                  performances de notre site et d'en améliorer le
                  fonctionnement.
                </p>
                <h3>Les cookies de préférences</h3>
                <p>
                  Ils servent à mieux comprendre vos préférences en se basant
                  sur vos activités précédentes ou actuelles sur notre site, ce
                  qui nous permet de vous fournir des services personnalisés.
                </p>
                <h3>Les cookies de mesure d&apos;audience</h3>
                <p>
                  Thank-you.io est responsable de traitement des données
                  collectées et traitées. Sur la base de son intérêt légitime,
                  ces traceurs sont destinés à la production de statistiques de
                  fréquentation anonymes. En désactivant ces cookies, nous ne
                  pourrons pas analyser le trafic du site.
                </p>
                <div>
                  {isNotTracking ? (
                    <button
                      className="whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-brand hover:bg-green-brandHover"
                      onClick={() => {
                        window.ThankYou.doTrack();
                        setIsNotTracking(false);
                      }}
                    >
                      Activer les cookies de mesure d&apos;audience
                    </button>
                  ) : (
                    <button
                      className="whitespace-nowrap inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-brand hover:bg-green-brandHover"
                      onClick={() => {
                        window.ThankYou.doNotTrack();
                        setIsNotTracking(true);
                      }}
                    >
                      Désactiver les cookies de mesure d&apos;audience
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}
